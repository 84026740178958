<template>
	<div class="dashboard">
		<div class="dashboard__body">
			<!-- <h2 class="primary--text text-center">Abastecimiento</h2> -->
			<div class="boxes">
				<template v-for="(item, index) in items">
					<router-link
						v-if="
							checkModule({
								moduleName: item.moduleName,
								pageName: item.pageName,
							})
						"
						:to="{ name: item.to }"
						:key="index + '_route'"
						:style="{ position: 'relative' }"
					>
						<div
							class="text"
							:style="{
								position: 'relative',
							}"
						>
							<v-icon>{{ item.icon }}</v-icon>
							<div class="text__title" v-text="item.title"></div>
							<div
								class="text__description"
								v-text="item.description"
							></div>
							<v-btn
								height="26"
								width="26"
								color="red"
								fab
								dark
								small
								class="amountNotify"
								v-if="
									item.to == 'AgreementNotification' &&
									agreementNotificationsAmount
								"
							>
								<span class="text-caption">
									{{ agreementNotificationsAmount }}
								</span>
							</v-btn>
						</div>
					</router-link>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
	name: 'AgreementHomeView',
	data() {
		return {
			items: [
				{
					to: 'SearchAgreement',
					title: 'Buscador',
					description: '',
					moduleName: 'abastecimiento',
					pageName: 'buscarPrecontrato',
					icon: 'mdi-magnify',
				},
				{
					to: 'CreateAgreement',
					title: 'Crear documento',
					description: '',
					moduleName: 'abastecimiento',
					pageName: 'crearPrecontrato',
					icon: 'mdi-cloud-upload-outline',
				},
				{
					to: 'AgreementReport',
					title: 'Reporte',
					description: '',
					moduleName: 'abastecimiento',
					pageName: 'reportePrecontrato',
					icon: 'mdi-microsoft-excel',
				},
				{
					to: 'AgreementNotification',
					title: 'Notificaciones',
					description: '',
					moduleName: 'abastecimiento',
					pageName: 'listarNotificaciones',
					icon: 'mdi-bell-outline',
				},
				{
					to: 'AgreementTypeList',
					title: 'Tipos de documento',
					description: '',
					moduleName: 'abastecimiento',
					pageName: 'listaTiposDocumento',
					icon: 'mdi-text-box-multiple-outline',
				},
			],
		};
	},
	created() {
		// this.getNotificationAmount({
		// 	read_agreement: 0,
		// 	status: 'APPROVED,OBSERVED,CONFIRMED',
		// });
	},
	computed: {
		...mapState('agreement', ['agreementNotificationsAmount']),
	},
	methods: {
		...mapActions('agreement', ['getNotificationAmount']),

		checkModule({ moduleName = '', pageName = '' }) {
			let found = false;
			const activities = this.$store.state.auth.activities || [];
			if (Array.isArray(activities)) {
				found = activities.some((activity) => {
					return (
						activity.module_name == moduleName &&
						activity.page_name == pageName
					);
				});
			}
			return found;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/dashboardHome.scss';
</style>
<style lang="scss">
.amountNotify {
	position: absolute;
	right: 0;
	top: 0;
	transform: translate(-50%, -70%);
}
</style>
